import axios from 'axios';
import { createBrowserHistory } from 'history';
import { whitelist } from '../security/config';
import { logOut } from '../security/auth';
import { refreshToken } from '../../login/AuthServices';
import { TENANT_ID, USER_ID, USERNAME } from '../../constants';

const history = createBrowserHistory();
let isRefreshingToken = false;
let subscribers = [];

function shouldCheck(pathToCheck) {
    const basePath = getBasePath(pathToCheck);
    return !whitelist.some(path => basePath.includes(path))
}

axios.interceptors.request.use((config) => {
    if(shouldCheck(config.url)){
        const now = new Date().getTime() / 1000;
        if(localStorage.getItem('access_token') !== null && now < localStorage.getItem('tokenExp') && now < localStorage.getItem('refreshExp')){
            config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
        } else {
            logOut();
            isRefreshingToken = false;
            window.location = '/login';
        }
    }
    
    // if(TENANT_ID) config.headers.tenantId = TENANT_ID;
    // if(USER_ID) config.headers.userId = USER_ID;
    // if(USERNAME) config.headers.username = USERNAME;

    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        let errRes = error.response;
        if (errRes) {
            switch(errRes.status) {
                case 400:
                    return errRes;
                case 401:
                    // try to refresh token then try again one more time
                    const originalRequest = error.config;
                    if (!isRefreshingToken) {
                        isRefreshingToken = true;
                        
                        return refreshToken().then((res) => {
                            error.config.headers.Authentication = `Bearer ${res.access_token}` // set new token
                            isRefreshingToken = false;
                            onAccessTokenFetched(res.access_token);
                            return axios.request(error.config); // retry request
                        }).catch(err => {
                            if (err.response.status === 401) {
                                logOut();
                                isRefreshingToken = false;
                                window.location = '/login';
                            }
                        });
                    } else {
                        subscribers.push(accessToken => {
                            originalRequest.headers.Authorization = 'Bearer ' + accessToken;
                            axios.request(originalRequest);
                        });
                    }
                    break;
                case 403:
                    logOut();
                    isRefreshingToken = false;
                    window.location = '/login';
                    break;
                default:
                    break;
            }
        }else{
            if(error.includes('failed with status code 403')){
                logOut();
                isRefreshingToken = false;
                window.location = '/login';
            }
        }

        return Promise.reject(error);
    }
);

function onAccessTokenFetched(accessToken) {
    subscribers.forEach(callback => callback(accessToken));
    subscribers = [];
}

function getBasePath(url) {
    const regex = /(http[s]?:\/\/)?([^\\/\s]+\/)(.*)/;
    let m = regex.exec(url);
    return m ? m[3] : "";
}