import { formatMoney } from "accounting-js";
import { CHANNEL_TYPE, PAYMENT_STATE, PAYMENT_GATEWAY, PAYMENT_METHOD, SHIPPING_STATE, CURRENCY_OBJECT, USE_FOR_PURPOSE, PRICING_MODE, ITEM_ORDER_TYPE, ORDER_ITEM_STATUS, ORDER_ADJUSTMENT_TYPE, ORDER_PAY_MODE, TABLE_STATUS, TABLE_REQUEST_STATUS, ORDER_BATCH_TYPE, ADJUSTMENT_TYPE } from "../../constants";

export function getTenantId() {
    return localStorage.getItem('client_id')
}

export function getApplicationConfig() {
    return localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {}
}

export function getTimezone() {
    const val = localStorage.getItem('timezone')
    return val && val !== 'null' && val !== 'undefined' ? val : null;
}

export function getApplicationUrl() {
    return localStorage.getItem('resApplicationUrl');
}

export function getEnableLocales() {
    return localStorage.getItem('enabledLocales');
}

export function getDefaultLocale() {
    return localStorage.getItem('defaultLocale');
}

export function getSeletecLocale() {
    return localStorage.getItem('selectedLocale');
}

export function replaceSpaceAndUpperCase(data) {
    let str = data.toUpperCase().replace(/ /g, "_");
    return str
}

export function titleCaseText(data) {
    let str;
    if (data) str = data.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    return str;
}

export function upperCaseField(data) {
    let str = data.toUpperCase();
    return str
}

export function TableStatusLine(page, rows, total) {
    let first = page * rows;
    let last = page * rows + rows;
    if (last > total) last = total;
    return `Showing ${first} to ${last} of ${total} entries`;
};

export function isUsePinCode(type) {
    if (type === USE_FOR_PURPOSE.res_dine_in)
        return localStorage.getItem('useDineInPinCode') === 'true';
    else
        return localStorage.getItem('useTakeAwayPinCode') === 'true';
}

export function getCustomerId() {
    return localStorage.getItem('customerId') ? parseInt(localStorage.getItem('customerId')) : null;
}

export function getCurrencySymbol() {
    const currency = CURRENCY_OBJECT[localStorage.getItem('currency')];
    return currency ? currency.symbol : '$';
}

export function getDecimalPlaces() {
    const currency = CURRENCY_OBJECT[localStorage.getItem('currency')];
    return currency ? currency.decimal : '2';
}

export function moneyFormat(amount, symbol = getCurrencySymbol(), precision = getDecimalPlaces()) {
    return amount ? formatMoney(amount, { symbol: symbol ? symbol : '$', format: '%s %v', precision: precision, thousand: ',', decimal: '.' }) : 'NaN'
}

export function isPriceIncludedTaxOrService() {
    return localStorage.getItem('pricingMode') !== PRICING_MODE.normal;
}

export function getGroupOrderItems(orderItems, categories, isCombine, isCombineBatch) {
    let mapGroupItems = {};
    let hasNoneCategory = false;
    let adjustItems = [];
    let totalItem = 0;

    orderItems.forEach(item => {
        totalItem++;
        if (item.orderType === ITEM_ORDER_TYPE.order) {
            const cate = item.refObject && item.refObject.taxonId ? item.refObject.taxonId : 'none';
            const prodId = item.productId;

            let items = mapGroupItems[cate] && mapGroupItems[cate][prodId] ? [...mapGroupItems[cate][prodId].items] : [];

            let itemIdx = -1;
            if (isCombine)
                itemIdx = items.length > 0 ?
                    items.findIndex(i =>
                        i.taxonId === item.refObject.taxonId && i.productId === item.productId && i.variant.id === item.variant.id && i.note === item.note &&
                        isItemOptionValuesExisted(i.itemOptionValues, item.itemOptionValues) &&
                        (isCombineBatch || (!isCombineBatch && ((!item.batchId && !i.batchId) || (item.batchId === i.batchId)))))
                    : -1;

            if (itemIdx !== -1) {
                items[itemIdx].quantity += item.quantity;
                items[itemIdx].taxPrice += item.taxPrice;
                items[itemIdx].total += item.total;
                items[itemIdx].orderItemIds.push(item.id)
                items[itemIdx].orderItems.push(item);
                items[itemIdx].adjustments.push(...item.adjustments);
            } else {
                let tmpItem = {
                    id: item.id,
                    productId: item.productId,
                    taxonId: item.refObject.taxonId,
                    variant: item.variant,
                    variantName: item.productVariantName,
                    itemOptionValueTrans: item.itemOptionValueTrans,
                    itemOptionValues: item.itemOptionValues,
                    note: item.note,
                    batchId: item.batchId,
                    quantity: item.quantity,
                    taxPrice: item.taxPrice,
                    total: item.total,
                    orderItemIds: [item.id],
                    orderItems: [item],
                    status: item.status,
                    adjustments: item.adjustments,
                    customerId: item.customerId,
                    totalFinal: item.totalFinal,
                    earlyPaid: item.earlyPaid
                };

                items.push(tmpItem);
            }

            let adjustments = mapGroupItems[cate] && mapGroupItems[cate][prodId] ? [...mapGroupItems[cate][prodId].adjustments] : [];
            item.adjustments.forEach(adjust => {
                let adjustIdx = adjustments.findIndex(i => (adjust.type === ORDER_ADJUSTMENT_TYPE.manual_discount && adjust.groupKey === i.groupKey) || (adjust.rule && i.rule && i.rule.id === adjust.rule.id))

                if (adjustIdx !== -1) {
                    adjustments[adjustIdx].amount += adjust.amount;
                } else {
                    adjustments.push(adjust);
                }
            });

            mapGroupItems = {
                ...mapGroupItems,
                [cate]: {
                    ...mapGroupItems[cate],
                    [prodId]: {
                        id: item.productId,
                        name: item.productName,
                        names: item.productNames,
                        items: items,
                        adjustments: adjustments
                    }
                }
            }

            if (cate === 'none')
                hasNoneCategory = true;
        } else {
            adjustItems.push(item);
        }
    })

    let mapResult = [];

    if (Object.entries(mapGroupItems).length > 0 || adjustItems.length > 0) {
        categories.forEach(c => {
            if (mapGroupItems[c.id]) {
                let totalItemByCate = 0;
                Object.entries(mapGroupItems[c.id]).map(product => totalItemByCate += product[1].items.length);
                mapResult.push({
                    totalItem: totalItem,
                    totalItemByCate: totalItemByCate,
                    cateId: c.id,
                    cateName: c.name,
                    cateNames: c.trans.names,
                    products: Object.keys(mapGroupItems[c.id]).map(k => (mapGroupItems[c.id][k]))
                });
            }
        });

        if (hasNoneCategory || adjustItems.length > 0) {
            mapResult.push({
                cateName: '',
                cateNames: '',
                products: mapGroupItems.none ? Object.keys(mapGroupItems.none).map(k => (mapGroupItems.none[k])) : [],
                adjustItems: adjustItems
            });
        }
    }

    return mapResult;
}

export function getGroupOrderBatchItems(orderItems, categories) {
    let mapGroupItems = {};
    let hasNoneCategory = false;
    let adjustItems = [];

    orderItems.forEach(item => {
        if (item.orderType === ITEM_ORDER_TYPE.order) {
            const cate = item.refObject && item.refObject.taxonId ? item.refObject.taxonId : 'none';
            const prodId = item.productId;

            let items = mapGroupItems[cate] && mapGroupItems[cate][prodId] ? [...mapGroupItems[cate][prodId].items] : [];
            let itemIdx = items.length > 0 ? items.findIndex(i => i.taxonId === item.refObject.taxonId && i.productId === item.productId && i.variant.id === item.variant.id && i.note === item.note && isItemOptionValuesExisted(i.itemOptionValues, item.itemOptionValues)) : -1;

            if (itemIdx !== -1) {
                items[itemIdx].quantity += item.quantity
                items[itemIdx].orderItemIds.push(item.id)
                items[itemIdx].orderItems.push(item)
            } else {
                let tmpItem = {
                    productId: item.productId,
                    taxonId: item.refObject.taxonId,
                    variant: item.variant,
                    note: item.note,
                    quantity: item.quantity,
                    status: item.status,
                    orderItemIds: [item.id],
                    orderItems: [item],
                    variantName: item.productVariantName,
                    itemOptionValues: item.itemOptionValues,
                    itemOptionValueTrans: item.itemOptionValueTrans,
                };

                items.push(tmpItem);
            }

            mapGroupItems = {
                ...mapGroupItems,
                [cate]: {
                    ...mapGroupItems[cate],
                    [prodId]: {
                        name: item.productName,
                        names: item.productNames,
                        items: items
                    }
                }
            }

            if (cate === 'none')
                hasNoneCategory = true;
        } else {
            adjustItems.push(item);
        }
    })

    let mapResult = [];

    if (Object.entries(mapGroupItems).length > 0 || adjustItems.length > 0) {
        categories.forEach(c => {
            if (mapGroupItems[c.id]) {
                mapResult.push({
                    cateName: c.name,
                    cateNames: c.trans.names,
                    products: Object.keys(mapGroupItems[c.id]).map(k => (mapGroupItems[c.id][k]))
                });
            }
        });

        if (hasNoneCategory || adjustItems.length > 0) {
            mapResult.push({
                cateName: '',
                cateNames: '',
                products: mapGroupItems.none ? Object.keys(mapGroupItems.none).map(k => (mapGroupItems.none[k])) : [],
                adjustItems: adjustItems
            });
        }
    }

    return mapResult;
}

export function getGroupProductBatchItems(orderItems, station) {
    let mapProductItems = {};

    orderItems.forEach(item => {
        if (!station || (station && item.refObject.locationId === station)) {
            const prodId = item.orderType === ITEM_ORDER_TYPE.order ? item.productId : item.productName.trim().toLowerCase().replace(/\s/g, '');

            let items = mapProductItems[prodId] ? [...mapProductItems[prodId].items] : [];
            let itemIdx = items.length > 0 ? items.findIndex(i => i.taxonId === item.refObject.taxonId && i.productId === item.productId && i.variant.id === item.variant.id && i.note === item.note && i.batchId === item.batchId && isItemOptionValuesExisted(i.itemOptionValues, item.itemOptionValues)) : -1;

            if (itemIdx !== -1) {
                let itemIds = [...items[itemIdx].orderItemIds]
                itemIds.push(item.id)

                items[itemIdx].itemKey = itemIds.join('_')
                items[itemIdx].quantity += item.quantity
                items[itemIdx].orderItemIds = itemIds
                items[itemIdx].orderItems.push(item)
            } else {
                let tmpItem = {
                    itemKey: item.id,
                    orderNumber: item.orderNumber,
                    productId: item.productId,
                    taxonId: item.refObject.taxonId,
                    variant: item.variant,
                    note: item.note,
                    quantity: item.quantity,
                    status: item.status,
                    orderItemIds: [item.id],
                    orderItems: [item],
                    variantName: item.productVariantName,
                    itemOptionValues: item.itemOptionValues,
                    itemOptionValueTrans: item.itemOptionValueTrans,
                    batchCreatedOn: item.batchCreatedOn,
                    batchId: item.batchId
                };

                items.push(tmpItem);
            }

            mapProductItems = {
                ...mapProductItems,
                [prodId]: {
                    id: item.productId,
                    name: item.productName,
                    names: item.productNames,
                    items: items
                }
            }
        }
    })

    return mapProductItems;
}

export function getTableOrderSummary(orders, orderInvoices, paymentMode, mergeInvoices) {
    let qtyTotal = 0;
    let subTotal = 0;
    let subTotalNoTax = 0;
    let subTotalWithTax = 0;
    let itemDiscount = 0;
    let orderAdjusts = [];
    let orderDiscounts = [];
    let couponDiscount = null;
    let serviceRate = 0;
    let serviceTotal = 0;
    let taxTotal = 0;
    let mergeInvoiceTotal = 0;
    let orderTotal = 0;
    let tipTotal = 0;
    let taxs = {};
    let shippingFee = orders[0].shippingFee ? orders[0].shippingFee : 0;

    orders.forEach(order => {
        order.items.forEach(item => {
            if (order.channel === CHANNEL_TYPE.restaurant_4 || order.channel === CHANNEL_TYPE.restaurant_2 || order.channel === CHANNEL_TYPE.restaurant_3 || (order.channel === CHANNEL_TYPE.restaurant && ((paymentMode === ORDER_PAY_MODE.post_pay && item.newStatus !== ORDER_ITEM_STATUS.added && item.newStatus !== ORDER_ITEM_STATUS.confirming) || (paymentMode === ORDER_PAY_MODE.pre_pay && item.newStatus !== ORDER_ITEM_STATUS.added)))) {
                qtyTotal += item.quantity;

                subTotal += item.total;
                subTotalNoTax += item.totalNotIncluding;
                subTotalWithTax += item.totalFinal;
                itemDiscount += item.adjustmentTotal;

                serviceRate = item.serviceRate;
                serviceTotal += item.servicePrice;
                taxTotal += item.taxPrice;

                if (item.taxCateId) {
                    if (!taxs[item.taxCateId]) {
                        taxs = {
                            ...taxs,
                            [item.taxCateId]: {
                                name: item.taxCate.name,
                                rate: item.taxRate,
                                total: item.taxPrice
                            }
                        }
                    } else {
                        taxs = {
                            ...taxs,
                            [item.taxCateId]: {
                                ...taxs[item.taxCateId],
                                total: taxs[item.taxCate.id].total + item.taxPrice
                            }
                        }
                    }
                }

                orderTotal += item.totalFinal;
            }
        });

        order.adjustments.forEach(adjust => {
            if (adjust.type === ORDER_ADJUSTMENT_TYPE.promotion || adjust.type === ORDER_ADJUSTMENT_TYPE.manual_discount) {
                // let adjustIdx = -1
                let adjustIdx = orderDiscounts.findIndex(i => (adjust.type === ORDER_ADJUSTMENT_TYPE.manual_discount && adjust.orderItemId && adjust.groupKey === i.groupKey) || (i.rule && adjust.rule && i.rule.id === adjust.rule.id))

                if (adjustIdx !== -1) {
                    let tmpIds = [...orderDiscounts[adjustIdx].ids]
                    tmpIds.push(adjust.id)

                    orderDiscounts[adjustIdx].ids = tmpIds
                    orderDiscounts[adjustIdx].amount += adjust.amount;
                } else {
                    adjust.ids = [adjust.id]
                    orderDiscounts.push(adjust);
                }
            } else if (adjust.type === ORDER_ADJUSTMENT_TYPE.order_adjust) {
                orderAdjusts.push(adjust);
            }

            // if(adjust.coupon){
            //     couponDiscount = adjust;
            // }
        });

        if (shippingFee !== null || shippingFee >= 0) {
            orderTotal += shippingFee;
        }
    });

    if (orderInvoices) {
        orderInvoices.forEach(i => {
            tipTotal += i.tip;
        })
    }

    if (mergeInvoices && mergeInvoices.length > 0) {
        mergeInvoices.forEach(i => {
            mergeInvoiceTotal += i.checkout
            orderTotal += i.checkout
        });
    }

    return {
        qtyTotal: qtyTotal,
        subTotal: subTotal,
        subTotalNoTax: subTotalNoTax,
        subTotalWithTax: subTotalWithTax,
        itemDiscount: itemDiscount,
        serviceRate: serviceRate,
        serviceTotal: serviceTotal,
        taxTotal: taxTotal,
        orderAdjusts: orderAdjusts,
        orderDiscounts: orderDiscounts.sort((a, b) => (!a.orderItemId ? 1 : -1)),
        couponDiscount: couponDiscount,
        tipTotal: tipTotal,
        mergeInvoiceTotal: mergeInvoiceTotal,
        orderTotal: orderTotal += tipTotal,
        taxs: taxs,
        shippingFee: shippingFee !== null ? shippingFee : null
    };
}

export function translatePaymentState(trans, state) {
    switch (state) {
        case PAYMENT_STATE.pending:
        case PAYMENT_STATE.awaiting:
            return trans('res.mgt.awaiting_payment');
        case PAYMENT_STATE.completing:
            return trans('res.mgt.waiting_verify_payment')
        case PAYMENT_STATE.completed:
            return trans('res.mgt.completed');
        default:
            return "";
    }
}

export function translateShippingState(trans, state) {
    switch (state) {
        case SHIPPING_STATE.pending:
            return trans('res.mgt.delivery_pending');
        case SHIPPING_STATE.preparing:
            return trans('res.mgt.delivery_preparing');
        case SHIPPING_STATE.ready:
            return trans('res.mgt.delivery_ready');
        case SHIPPING_STATE.completed:
            return trans('res.mgt.completed');
        default:
            return "";
    }
}

export function convertQueueNumber(num) {
    if (num) {
        let s = '0000' + num.toString();

        return s.slice(-4);
    } else {
        return "";
    }

}

export function calculateSplitLimit(limitPerSplit, splitNum, total) {
    let split = (total / splitNum).toFixed(2);
    let numOfSplit = splitNum;
    while (split < limitPerSplit) {
        split = (total / numOfSplit).toFixed(2);
        numOfSplit = numOfSplit - 1;
    }
    if (numOfSplit <= 2)
        return 2;
    else {
        return numOfSplit;
    }
}

export function translateBatchStatus(trans, status) {
    switch (status) {
        case ORDER_BATCH_TYPE.pending:
            return trans('res.mgt.delivery_pending');
        case ORDER_BATCH_TYPE.preparing:
            return trans('res.mgt.delivery_preparing');
        case ORDER_ITEM_STATUS.completed:
            return trans('res.mgt.completed');
        case ORDER_ITEM_STATUS.stopped:
            return trans('res.mgt.stopped');
        default: return "";
    }
}

export function translateOrderItemStatus(trans, status) {
    switch (status) {
        case ORDER_ITEM_STATUS.pending:
            return trans('res.mgt.delivery_pending');
        case ORDER_ITEM_STATUS.waiting:
            return trans('res.mgt.waiting');
        case ORDER_ITEM_STATUS.processing:
            return trans('res.mgt.dish_processing');
        case ORDER_ITEM_STATUS.completed:
            return trans('res.mgt.completed');
        case ORDER_ITEM_STATUS.stopped:
            return trans('res.mgt.stopped');
        default: return "";
    }
}

function isItemOptionValuesExisted(a, b) {
    if (!a && !b)
        return true

    if (a && b && a.length === b.length) {
        if (a.length > 0 && b.length > 0) {
            for (var i = 0; i < a.length; i++) {
                if (a[i].id !== b[i].id) {
                    return false;
                }
            }
        }

        return true;
    } else {
        return false;
    }
}

export function addStyleWithClass(mainClass, attrKey, attrValue) {
    let nodes = document.getElementsByClassName(mainClass);
    if (nodes.length > 0) {
        nodes.forEach(element => {
            element.firstChild.setAttribute(attrKey, attrValue)
        });
    }
}

export function translatePostPayTableStatus(trans, tableCustomers, tableStatus, requestStatus, openOrder) {
    switch (requestStatus) {
        case TABLE_REQUEST_STATUS.confirm_request:
            return trans('res.mgt.table_order_waiting_confirm');
        case TABLE_REQUEST_STATUS.check_request:
            return trans('res.mgt.requested_checkout');
        case TABLE_REQUEST_STATUS.waiting_payment:
        case TABLE_REQUEST_STATUS.making_payment:
        case TABLE_REQUEST_STATUS.payment_splitted:
            return trans('res.mgt.waiting_payment');
        case TABLE_REQUEST_STATUS.splitting_payment:
            return trans('res.mgt.splitting_payment');
        case TABLE_REQUEST_STATUS.completing_payment:
            if (openOrder.paymentGateway === PAYMENT_GATEWAY.ONSITE) {
                switch (openOrder.paymentMethod) {
                    case PAYMENT_METHOD.cash:
                        return trans('res.mgt.cash_payment_requested');
                    case PAYMENT_METHOD.swipe_card:
                        return trans('res.mgt.swipe_card_payment_requested');
                    case PAYMENT_METHOD.paynow_offline:
                        return trans('res.mgt.paynow_payment_requested');
                    case PAYMENT_METHOD.paynow_online:
                    case PAYMENT_METHOD.alipay:
                    case PAYMENT_METHOD.wechat:
                    case PAYMENT_METHOD.grabpay:
                        return trans('res.mgt.hitpay_assited_payment_request');
                    default: break
                }
            } else {
                return trans('res.mgt.verifying_payment');
            }
            break;
        case TABLE_REQUEST_STATUS.complete_payment:
            return trans('res.mgt.complete_payment')
        default:
            if (tableStatus === TABLE_STATUS.occupied || tableCustomers > 0)
                return trans('res.mgt.no_request');
            return trans('res.mgt.emtpy_table_status');
    }
}

export function translatePrePayOrderStatus(data, trans) {
    if (data.paymentStatus && data.paymentStatus === PAYMENT_STATE.pending && !data.shippingStatus) {
        return trans('res.mgt.waiting_confirm');
    } else {
        if (data.paymentStatus === PAYMENT_STATE.awaiting)
            return trans('res.mgt.awaiting_payment');
        else if (data.paymentStatus === PAYMENT_STATE.completing) {
            switch (data.paymentMethod) {
                case PAYMENT_METHOD.cash:
                    return trans('res.mgt.cash_payment_requested');
                case PAYMENT_METHOD.swipe_card:
                    return trans('res.mgt.swipe_card_payment_requested');
                case PAYMENT_METHOD.paynow_offline:
                    return trans('res.mgt.paynow_payment_requested');
                case PAYMENT_METHOD.paynow_online:
                case PAYMENT_METHOD.alipay:
                case PAYMENT_METHOD.wechat:
                case PAYMENT_METHOD.grabpay:
                    return trans('res.mgt.hitpay_assited_payment_request');
                default: break
            }
        } else {
            return translateShippingState(trans, data.shippingStatus);
        }
    }
}

export function translateListWithLocale(dataList) {
    const selectedLocale = getSeletecLocale();
    const defaultLocale = getDefaultLocale();
    let result = []

    dataList.forEach(item => {
        const itemName = item.trans && item.trans.names ? item.trans.names[selectedLocale] ?? item.trans.names[defaultLocale] ?? item.name : item.name

        result.push({ label: itemName, value: item.id })
    })

    return result;
}

export function getArrayValueFromList(data) {
    if (data && data.length > 0)
        return data.map(item => (item.value))

    return []
}

export function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}

export function isArrayEqual(arr_1, arr_2) {
    let i = arr_1.length;
    if (i !== arr_2.length) return false;
    while (i--) {
        if (arr_1[i] !== arr_2[i]) return false;
    }
    return true;
}

export function renderVariant(item, selectedLocale, defaultLocale) {
    if (item.itemOptionValueTrans.length > 0)
        return item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ?? opt.names[defaultLocale] ?? item.variantName)).join(', ')

    return item.variantName
}