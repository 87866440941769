export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const USER_ID = process.env.REACT_APP_USER_ID;
export const USERNAME = process.env.REACT_APP_USERNAME;

let loginURL = '';
if (process.env.REACT_APP_LOGIN_BASE_URL) {
  loginURL = process.env.REACT_APP_LOGIN_BASE_URL;
} else {
  loginURL = process.env.REACT_APP_API_BASE_URL;
};
export const LOGIN_BASE_URL = loginURL;

let menuUrl = '';
if (process.env.REACT_APP_MENU_BASE_URL) {
  menuUrl = process.env.REACT_APP_MENU_BASE_URL;
} else {
  menuUrl = process.env.REACT_APP_API_BASE_URL;
};

let pimUrl = '';
if (process.env.REACT_APP_API_PIM_URL) {
  pimUrl = process.env.REACT_APP_API_PIM_URL;
} else {
  pimUrl = process.env.REACT_APP_API_BASE_URL;
};

let mailServiceUrl = '';
if (process.env.REACT_APP_MAIL_BASE_URL) {
  mailServiceUrl = process.env.REACT_APP_MAIL_BASE_URL;
} else {
  mailServiceUrl = process.env.REACT_APP_API_BASE_URL;
};

let tableUrl = '';
if (process.env.REACT_APP_TABLE_BASE_URL) {
  tableUrl = process.env.REACT_APP_TABLE_BASE_URL;
} else {
  tableUrl = process.env.REACT_APP_API_BASE_URL;
};

let orderUrl = '';
if (process.env.REACT_APP_ORDER_BASE_URL) {
  orderUrl = process.env.REACT_APP_ORDER_BASE_URL;
} else {
  orderUrl = process.env.REACT_APP_API_BASE_URL;
};

let taxUrl = '';
if (process.env.REACT_APP_TAX_BASE_URL) {
  taxUrl = process.env.REACT_APP_TAX_BASE_URL;
} else {
  taxUrl = process.env.REACT_APP_API_BASE_URL;
};

let tagUrl = '';
if (process.env.REACT_APP_TAG_BASE_URL) {
  tagUrl = process.env.REACT_APP_TAG_BASE_URL;
} else {
  tagUrl = process.env.REACT_APP_API_BASE_URL;
};

let uploadUrl = '';
if (process.env.REACT_APP_UPLOAD_BASE_URL) {
  uploadUrl = process.env.REACT_APP_UPLOAD_BASE_URL;
} else {
  uploadUrl = process.env.REACT_APP_API_BASE_URL;
};

let userUrl = '';
if (process.env.REACT_APP_USER_BASE_URL) {
  userUrl = process.env.REACT_APP_USER_BASE_URL;
} else {
  userUrl = process.env.REACT_APP_API_BASE_URL;
};

let paymentUrl = '';
if (process.env.REACT_APP_PAYMENT_BASE_URL) {
  paymentUrl = process.env.REACT_APP_PAYMENT_BASE_URL;
} else {
  paymentUrl = process.env.REACT_APP_API_BASE_URL;
};

let tableOrderUrl = '';
if (process.env.REACT_APP_TABLE_ORDER_BASE_URL) {
  tableOrderUrl = process.env.REACT_APP_TABLE_ORDER_BASE_URL;
} else {
  tableOrderUrl = process.env.REACT_APP_API_BASE_URL;
};

let reportUrl = '';
if (process.env.REACT_APP_REPORT_BASE_URL) {
  reportUrl = process.env.REACT_APP_REPORT_BASE_URL;
} else {
  reportUrl = process.env.REACT_APP_API_BASE_URL;
};

let invoiceUrl = '';
if (process.env.REACT_APP_INVOICE_BASE_URL) {
  invoiceUrl = process.env.REACT_APP_INVOICE_BASE_URL;
} else {
  invoiceUrl = process.env.REACT_APP_API_BASE_URL;
};

let promotionUrl = '';
if (process.env.REACT_APP_PROMOTION_BASE_URL) {
  promotionUrl = process.env.REACT_APP_PROMOTION_BASE_URL;
} else {
  promotionUrl = process.env.REACT_APP_API_BASE_URL;
};

let paymentTransactionUrl = '';
if (process.env.REACT_APP_PAYMENT_TRANSACTION_BASE_URL) {
  paymentTransactionUrl = process.env.REACT_APP_PAYMENT_TRANSACTION_BASE_URL;
} else {
  paymentTransactionUrl = process.env.REACT_APP_API_BASE_URL;
};

let statisticUrl = '';
if (process.env.REACT_APP_STATISTIC_BASE_URL) {
  statisticUrl = process.env.REACT_APP_STATISTIC_BASE_URL;
} else {
  statisticUrl = process.env.REACT_APP_API_BASE_URL;
};

let ratingUrl = '';
if (process.env.REACT_APP_RATING_BASE_URL) {
  ratingUrl = process.env.REACT_APP_RATING_BASE_URL;
} else {
  ratingUrl = process.env.REACT_APP_API_BASE_URL;
};

let voucherUrl = '';
if (process.env.REACT_APP_VOUCHER_BASE_URL) {
  voucherUrl = process.env.REACT_APP_VOUCHER_BASE_URL;
} else {
  voucherUrl = process.env.REACT_APP_API_BASE_URL;
};

let quickbooksUrl = '';
if (process.env.REACT_APP_QUICKBOOKS_BASE_URL) {
  quickbooksUrl = process.env.REACT_APP_QUICKBOOKS_BASE_URL;
} else {
  quickbooksUrl = process.env.REACT_APP_API_BASE_URL;
};

let quickbooksLoginUrl = '';
if (process.env.REACT_APP_QUICKBOOKS_BASE_URL) {
  quickbooksLoginUrl = process.env.REACT_APP_QUICKBOOKS_LOGIN_BASE_URL;
} else {
  quickbooksLoginUrl = process.env.REACT_APP_API_BASE_URL;
};

let orderActivityUrl = '';
if (process.env.REACT_APP_PATH_ORDER_ACTIVITY) {
  orderActivityUrl = process.env.REACT_APP_API_BASE_URL;
} else {
  orderActivityUrl = process.env.REACT_APP_API_BASE_URL;
}
  
let userProfileUrl = '';
if (process.env.REACT_APP_USER_PROFILE_BASE_URL) {
  userProfileUrl = process.env.REACT_APP_USER_PROFILE_BASE_URL;
} else {
  userProfileUrl = process.env.REACT_APP_API_BASE_URL;
};

export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;
export const WS_FALLBACK_BASE_URL = process.env.REACT_APP_WS_FALLBACK_BASE_URL;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
export const TENANT = process.env.REACT_APP_TENANT;

export const MENU_PATH = '/menu/api';
export const TABLE_PATH = '/api/table';
export const ORDER_PATH = '/api/orders';
export const PIM_PATH = '/pim/api';
export const MAIL_PATH = '/mail';
export const TAX_PATH = '/api/taxs';
export const TAG_PATH = '/api/tag';
export const UPLOAD_PATH = '/api/upload';
export const USER_PATH = '/api/user';
export const PAYMENT_PATH = '/payment/api';
export const TABLE_ORDER_PATH = '/api/table-order';
export const INVOICE_PATH = '/api/invoice';
export const PROMOTION_PATH = '/api/promotions';
export const REPORT_PATH = '/api/reports';
export const PAYMENT_TRANSACTION_PATH = '/api/payment-transaction';
export const STATISTIC_PATH = '/api/statistic';
export const RATING_PATH = '/api/rating';
export const VOUCHER_PATH = '/api/voucher';
export const QUICKBOOKS_PATH = '/api/quickbooks/sdk';
export const QUICKBOOKS_LOGIN_PATH = '/api/quickbooks/connect';
export const ORDER_ACTIVITY_PATH = '/api/order-notification';
export const USER_PROFILE_PATH = '/api/user-profile';
export const GRAB_PATH = '/api/grab';
export const GOOGLE_PATH = '/api/google';

export const MENU_BASE_URL = menuUrl + MENU_PATH;
export const PIM_BASE_URL = pimUrl + PIM_PATH;
export const TABLE_BASE_URL = tableUrl + TABLE_PATH;
export const ORDER_BASE_URL = orderUrl + ORDER_PATH;
export const TAX_BASE_URL = taxUrl + TAX_PATH;
export const TAG_BASE_URL = tagUrl + TAG_PATH;
export const UPLOAD_BASE_URL = uploadUrl + UPLOAD_PATH;
export const USER_BASE_URL = userUrl + USER_PATH;
export const PAYMENT_BASE_URL = paymentUrl + PAYMENT_PATH;
export const TABLE_ORDER_BASE_URL = tableOrderUrl + TABLE_ORDER_PATH;
export const INVOICE_BASE_URL = invoiceUrl + INVOICE_PATH;
export const PROMOTION_BASE_URL = promotionUrl + PROMOTION_PATH;
export const REPORT_BASE_URL = reportUrl + REPORT_PATH;
export const PAYMENT_TRANSACTION_BASE_URL = paymentTransactionUrl + PAYMENT_TRANSACTION_PATH;
export const STATISTIC_BASE_URL = statisticUrl + STATISTIC_PATH;
export const RATING_BASE_URL = ratingUrl + RATING_PATH;
export const MAIL_BASE_URL = mailServiceUrl + MAIL_PATH;
export const VOUCHER_BASE_URL = voucherUrl + VOUCHER_PATH;
export const QUICKBOOKS_BASE_URL = quickbooksUrl + QUICKBOOKS_PATH;
export const QUICKBOOKS_LOGIN_BASE_URL = quickbooksLoginUrl + QUICKBOOKS_LOGIN_PATH;
export const ORDER_ACTIVITY_BASE_URL = orderActivityUrl + ORDER_ACTIVITY_PATH;
export const USER_PROFILE_BASE_URL = userProfileUrl + USER_PROFILE_PATH;

let configServices = '';
if(process.env.REACT_APP_CONFIGURATION_BASE_URL){
  configServices = process.env.REACT_APP_CONFIGURATION_BASE_URL;
} else {
  configServices = process.env.REACT_APP_API_BASE_URL;
}
export const CONFIGURATION_BASE_URL = configServices;

export const RECAPTCHA_GG_SITEKEY = process.env.REACT_APP_RECAPTCHA_GG_SITEKEY;

export const APP_FEP = 'fep'
export const APP_RES = 'res'

export function getTenantAlias() {
  let parts = window.location.host.split(".");
  let subdomain = "ymca";
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 3) {
      subdomain = parts[0];
  }
  return subdomain.split("-")[0];
};

export const VIEW_MODE = {
  LAYOUT_GRID: 'grid',
  LAYOUT_LIST: 'list'
}

export const MESSAGE = {
  ERROR_FIELD_INPUT_REQUIRED: "Field require input",
  ERROR_FIELD_SELECT_REQUIRED: "Please select a value",
  INVALID_NUMBER_FORMAT: "Wrong number",
  INVALID_EMAIL_FORMAT: "Wrong email format",
  DUPLICATED_VALUE: "This value has existed"
};

export const TABLE_PAGING_LENGTH = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];

export const NAME_TITLES = [
  { label: "Not use", value: "" },
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." }
];

export const GENDERS = [
  { label: "Any", value: "Any" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "NonBinary" }
];

export const FIELD_TYPE = {
  text: "text",
  number: "number",
  email: "email",
  phone: "phone",
  select: "select",
  radio: "radio",
  checkbox: "checkbox",
  boolean: "yes_no",
  textarea: "textarea",
  file: "file",
  date: "date",
  waiver: "waiver"
};

export const FIELD_EXPRESSION_TYPE = {
  none: 0,
  pattern: 1,
  maskString: 2
};

export const FIELD_DISPLAY_TYPE = {
  dropdown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textbox: "textbox",
  file: "file"
};

export const ENCRYPTED_OPTIONS = [
  { label: "UNENCRYPTED", value: "Unencrypted" },
  { label: "SSL/TLS", value: "SSLTLS" },
  { label: "STARTTLS", value: "STARTTLS" }
];

export const PAYMENT_GATEWAY = {
  ONSITE: "ONSITE",
  STRIPE: "STRIPE",
  SQUARE: "SQUARE",
  PAYPAL: "PAYPAL",
  MIDTRANS: "MIDTRANS",
  HITPAY: "HITPAY"
  // BRAINTREE: "BRAINTREE",
  // SLIM_CD: "SLIM_CD",
  // LUMINATE_DONATION_API: "LUMINATE_DONATION_API",
  // RED_DOT: "RED_DOT",
  // USER_CREDIT: "USER_CREDIT"
};

export const PAYMENT_GATEWAYS = {
  STRIPE: {label: 'STRIPE', value: "STRIPE"},
  SQUARE: {label: 'SQUARE', value: "SQUARE"},
  PAYPAL: {label: 'PAYPAL', value: "PAYPAL"},
  MIDTRANS: {label: 'MIDTRANS', value: "MIDTRANS"},
  HITPAY: {label: 'HITPAY', value: "HITPAY"}
  //BRAINTREE: {label: 'BRAINTREE', value: "BRAINTREE"},
  // {label: 'SLIM_CD', value: "SLIM_CD"},
  // {label: 'LUMINATE_DONATION_API', value: "LUMINATE_DONATION_API"},
  // {label: 'RED_DOT', value: "RED_DOT"},
}

export const PAYMENT_OPTION = {
  Onsite: 'Onsite',
  Online: 'Online'
}

export const PAYMENT_METHOD = {
  cash: "cash",
  swipe_card: "swipe_card",
  paynow_offline: "paynow_offline",
  card: "card",
  paynow_online: "paynow_online",
  alipay: "alipay",
  wechat: "wechat",
  grabpay: "grabpay"
};

export const PAYMENT_METHODS_OBJECT = {
  cash: {value: PAYMENT_METHOD.cash, label: 'res.mgt.cash'},
  swipe_card: {value: PAYMENT_METHOD.swipe_card, label: 'res.mgt.swipe_card'},
  paynow_offline: {value: PAYMENT_METHOD.paynow_offline, label: 'res.mgt.paynow_offline'},
  card: {value: PAYMENT_METHOD.card, label: 'res.mgt.credit_card'},
  paynow_online: {value: PAYMENT_METHOD.paynow_online, label: 'res.mgt.paynow_online'},
  alipay: {value: PAYMENT_METHOD.alipay, label: 'res.mgt.alipay'},
  wechat: {value: PAYMENT_METHOD.wechat, label: 'res.mgt.wechat_pay'},
  grabpay: {value: PAYMENT_METHOD.grabpay, label: 'res.mgt.grabpay'}
}

export const ONSITE_PAYMENT_METHODS = [
  {value: PAYMENT_METHOD.cash, label: 'res.mgt.cash'},
  {value: PAYMENT_METHOD.swipe_card, label: 'res.mgt.swipe_card'},
  {value: PAYMENT_METHOD.paynow_offline, label: 'res.mgt.paynow_offline'}
  // {value: PAYMENT_METHOD.paynow_online, label: 'PayNow'},
  // {value: PAYMENT_METHOD.alipay, label: 'Alipay'},
  // {value: PAYMENT_METHOD.wechat, label: 'Wechat Pay'}
];

export const HITPAY_PAYMENT_METHODS = [
  {value: PAYMENT_METHOD.card, label: 'res.mgt.amex_visa_master_card'},
  {value: PAYMENT_METHOD.paynow_online, label: 'res.mgt.paynow_online'},
  {value: PAYMENT_METHOD.alipay, label: 'res.mgt.alipay'},
  // {value: PAYMENT_METHOD.wechat, label: 'res.mgt.wechat_pay'},
  // {value: PAYMENT_METHOD.grabpay, label: 'res.mgt.grabpay'}
];

export const ORDER_STATE = {
  cart: 'cart',
  new_order: 'new_order',
  viewed: 'viewed',
  canceled: 'canceled'
};

export const PAYMENT_STATE = {
  pending: "pending",
  awaiting: "awaiting",
  completing: "completing",
  completed: "completed"
};

export const PAYMENT_STATES = {
  pending: {value: PAYMENT_STATE.pending, label: 'res.mgt.awaiting_payment'},
  awaiting: {value: PAYMENT_STATE.awaiting, label: 'res.mgt.awaiting_payment'},
  completing: {value: PAYMENT_STATE.completing, label: 'res.mgt.waiting_verify_payment'},
  completed: {value: PAYMENT_STATE.completed, label: 'res.mgt.completed'}
}

export const CHECKOUT_STATE = {
  cart: 'cart',
  waiting_participant: 'waiting_participant',
  waiting_address: 'waiting_address',
  waiting_checkout: 'waiting_checkout',
  completed: 'completed'
};

export const SHIPPING_STATE = {
  pending: 'pending',
  // waiting: 'waiting',
  preparing: 'preparing',
  ready: 'ready',
  shipping: 'shipping',
  shipped: 'shipped',
  completed: 'completed'
}

export const CHANNEL_TYPE = {
  restaurant: "restaurant",
  restaurant_2: "restaurant_2",
  restaurant_3: "restaurant_3",
  restaurant_4: "restaurant_4"
};

export const PRODUCT_TYPE = {
  personalTicket: "personal_ticket",
  groupPersonalTicket: "group_personal_ticket",
  normalTicket: "normal_ticket",
  groupNormalTicket: "group_normal_ticket",
  reservation: "reservation",
  session: "session",
  allocation: "allocation",
  addon: "addon",
  merchandize: "merchandize",
  membership: "membership",
  donation: "donation",
  dish: "dish"
};

export const ORDER_ITEM_STATUS = {
  added: 'added',
  confirming: 'confirming',
  confirmed: 'confirmed',
  pending: 'pending',
  waiting: 'waiting',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled',
  stopped: 'stopped'
}

export const ORDER_ITEM_STATUSES = [
  {value: ORDER_ITEM_STATUS.pending, label:'res.mgt.order_item_status_pending'},
  {value: ORDER_ITEM_STATUS.waiting, label:'res.mgt.order_item_status_waiting'},
  {value: ORDER_ITEM_STATUS.processing, label:'res.mgt.order_item_status_processing'},
  {value: ORDER_ITEM_STATUS.completed, label:'res.mgt.order_item_status_completed'},
  {value: ORDER_ITEM_STATUS.canceled, label:'res.mgt.order_item_status_canceled'},
  {value: ORDER_ITEM_STATUS.stopped, label:'res.mgt.order_item_status_stopped'}
]

export const NOTE_STATUSES = [
  { label: 'New', value: 'create' },
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Done', value: 'done' },
  { label: 'Reopen', value: 'reopen' },
];

export const TABLE_STATUS = {
  available: "available",
  occupied: "occupied"
}

export const TAXON_TYPE = {
  category: 'category',
  menu: 'menu',
  location: 'location'
};

export const FORMLOCK_TYPE = {
  table: 'table',
  menu: 'menu'
};

export const GetLabelFromListSimple = (list, value) => {
  let lable = '';
  let item = list.find(item => item.value === value);
  if(item){
    lable = item.label;
  }
  return lable;
};

export const RANGE_BIRTHDAY = (new Date().getFullYear() - 100) + ':' + (new Date().getFullYear() + 5)

export const TABLE_STATUSES = [
  {value: 'available', label: 'Available'},
  {value: 'occupied', label: 'Occupied'}
]

export const TABLE_REQUEST_STATUS = {
  confirm_request: 'confirm_request',
  check_request: 'check_request',
  waiting_payment: 'waiting_payment',
  splitting_payment: 'splitting_payment',
  payment_splitted: 'payment_splitted',
  making_payment: 'making_payment',
  completing_payment: 'completing_payment',
  complete_payment: 'complete_payment',
  order_confirmed: 'order_confirmed',
  cleaned: 'cleaned',
  pay_by_paynow_request: 'pay_by_paynow_request',
  pay_by_alipay_request: 'pay_by_alipay_request',
  pay_by_wechatpay_request: 'pay_by_wechatpay_request'
}

export const TABLE_REQUEST_STATUSES = [
  {value: 'confirm_request', label: 'Confirm order request'},
  {value: 'check_request', label: 'Check request'},
  {value: 'waiting_payment', label: 'Waiting payment'},
  {value: 'pay_by_cash_request', label: 'Pay by cash request'},
  {value: 'pay_by_swipe_card_request', label: 'Pay by swipe card request'},
  {value: 'complete_payment', label: "Payment completed"}
]

export const TAG_TYPE = {
  foodIngredient: 'foodIngredient',
  foodAllergen: 'foodAllergen'
};

export const TAG_TYPES = [
  {value: 'foodIngredient', label: 'Food Ingredient'},
  {value: 'foodAllergen', label: 'Food Allergen'}
];

export const USER_EVENTS = {
  message: 'message',
  newCustomer: 'newCustomer',
  tableCustomer: 'tableCustomer',
  tableRequestStatusChanged: 'tableRequestStatusChanged',
  tableOrderPlaced: 'tableOrderPlaced',
  waiterRequested: 'waiterRequested',
  waiterResponded: 'waiterResponded',
  tableProcessPayment: 'tableProcessPayment',
  itemAdded: 'itemAdded',
  itemUpdated: 'itemUpdated',
  itemRemoved: 'itemRemoved',
  orderPlaced: 'orderPlaced',
  orderUpdated: 'orderUpdated',
  orderPaymentStatusChange: 'orderPaymentStatusChange',
  orderShippingStatusChange: 'orderShippingStatusChange',
  orderDeliveryStatusChange: 'orderDeliveryStatusChange',
  orderStatusChange: 'orderStatusChange',
  requestMakePaymentForInvoice: 'requestMakePaymentForInvoice',
  invoiceStatusChange: 'invoiceStatusChange',
  newBatch: 'newBatch',
  batchStatusChange: 'batchStatusChange',
  batchItemStatusChange: 'batchItemStatusChange',
  batchPaymentCompleted: 'batchPaymentCompleted',
  batchRemoved: 'batchRemoved',
  makingPrepaymentWhileEating: 'makingPrepaymentWhileEating',
  completePrepaymentWhileEating: 'completePrepaymentWhileEating',
  tableNotification: 'tableNotification',
  tableNotifyMessage: 'tableNotifyMessage'
}

export const ORDER_ADJUSTMENT_TYPE = {
  promotion: 'promotion',
  order_adjust: 'order_adjust',
  manual_discount: 'manual_discount'
}

export const ITEM_ORDER_TYPE = {
  order: 'order',
  adjustment: 'adjustment'
}

export const ADJUSTMENT_TYPE = {
  add_item: 'add_item',
  order: 'order',
  discount: 'discount',
  service: 'service'
}

export const ADJUSTMENT_TYPES = [
  {value: 'discount', label: 'res.mgt.manual_discount'},
  {value: 'add_item', label: 'res.mgt.adjust_add_item'},
  // {value: 'order', label: 'res.mgt.adjust_order'},
  // {value: 'service', label: 'res.mgt.adjust_service'},
]

export const PRODUCT_OPTION_TYPE = {
  variant: 'variant',
  properties: 'properties'
}

export const PRODUCT_OPTION_TYPES = [
  {value: 'variant', label: 'res.mgt.option_variant'},
  {value: 'properties', label: 'res.mgt.option_properties'},
]

export const PRODUCT_VARIANT_TYPE = {
  simple: 'simple',
  configuration: 'configuration'
}

export const USE_FOR_PURPOSE = {
  res_dine_in: 'res_dine_in',
  res_take_out: 'res_take_out',
  res_time_order: 'res_time_order',
  res_delivery_now: 'res_delivery_now'
}

export const USE_FOR_PURPOSES = [
  {value: 'res_dine_in', label: 'res.mgt.dine_in'},
  {value: 'res_take_out', label: 'res.mgt.take_out'},
  // {value: 'res_time_order', label: 'res.mgt.time_order'},
  // {value: 'res_delivery_now', label: 'res.mgt.delivery_now'}
]

export const TABLE_TYPE = {
  dine_in: 'dine_in',
  take_away: 'take_away',
  time_order: 'time_order',
  delivery_now: 'delivery_now'
}

export const TABLE_TYPES = {
  dine_in: {value: 'dine_in', label: 'res.mgt.dine_in'},
  take_away: {value: 'take_away', label: 'res.mgt.take_away'},
  // time_order: {value: 'time_order', label: 'res.mgt.time_order'},
  // delivery_now: {value: 'delivery_now', label: 'res.mgt.delivery_now'},
}

export const TABLE_ORDER_STATUS = {
  open: 'open',
  closed: 'closed',
  canceled: 'canceled'
}

export const TABLE_ORDER_STATUSES = {
  open: {value: 'open', label: 'res.mgt.table_status_open'},
  closed: {value: 'closed', label: 'res.mgt.table_status_closed'},
  canceled: {value: 'canceled', label: 'res.mgt.table_status_canceled'}
}

export function translateListSimple(trans, list){
  const result = [...list];
  result.map(i => {
    i.label = trans(i.label);
  });
  return result;
}

export function translateItemListSimple(trans, list, value){
  const item = list.find(i => i.value===value);
  return item?trans(item.label):value;
}

export const REPORT_TYPE = {
  order: 'order',
  // transaction: 'transaction',
  invoice: 'invoice',
  revenue: 'revenue',
  product: 'product'
}

export const REPORT_TYPES = [
  {value: 'order', label: 'res.mgt.order'},
  // {value: 'transaction', label: 'res.mgt.transaction'}
  {value: 'invoice', label: 'res.mgt.invoice'}
]

export const INFORM_TYPE = {
  thankyou: 'thankyou',
  access_denied: 'access_denied',
  order_inform: 'order_inform',
  not_found: 'not_found'
}

export const PAYMENT_USE_MODE = { 
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION'
}

export const PAYMENT_USE_MODES = [
  { label: "TEST", value: 'TEST'},
  { label: "PRODUCTION", value: 'PRODUCTION'}
]

export const OBJECT_TYPE = {
  product: 'product',
  order: 'order'
}

export const PAYMENT_STATUS = {
  pending: 'pending',
  processing: 'processing',
  cancel: 'cancel',
  completed: 'completed'
}

export const PAYMENT_STATUSES = {
  pending: {value: PAYMENT_STATUS.pending, label: 'res.mgt.payment_status_pending'},
  // processing: {value: PAYMENT_STATUS.processing, label: 'res.mgt.payment_status_processing'},
  // cancel: {value: PAYMENT_STATUS.cancel, label: 'res.mgt.payment_status_cancel'},
  completed: {value: PAYMENT_STATUS.completed, label: 'res.mgt.payment_status_completed'}
}
  

export const PRICING_MODE = {
  normal: "normal",
  included_tax_service: "included_tax_service",
  included_tax: "included_tax",
  included_service: "included_service"
}

export const CURRENCIES = [
  {label: 'USD', value: 'USD'},
  {label: 'EUR', value: 'EUR'},
  {label: 'SGD', value: 'SGD'},
  {label: 'IDR', value: 'IDR'},
];

export const CURRENCY_OBJECT = {
  USD: {symbol: '$', decimal: 2},
  EUR: {symbol: '€', decimal: 2},
  SGD: {symbol: 'S$', decimal: 2},
  IDR: {symbol: 'Rp', decimal: 0}
}

export const ORDER_PAY_MODE = {
  pre_pay: 'pre_pay',
  post_pay: 'post_pay'
}

export const ORDER_PAY_MODES = {
  pre_pay: {label: 'res.mgt.order_pay_mode_pre_pay', value: 'pre_pay'},
  post_pay: {label: 'res.mgt.order_pay_mode_post_pay', value: 'post_pay'}
}

export const MAIL_SMTP_ENCRYPTION = {
  Unencrypted: 'Unencrypted', SSLTLS: 'SSLTLS', STARTTLS: 'STARTTLS'
}

export const MAIL_SMTP_ENCRYPTIONS = [
  {value: MAIL_SMTP_ENCRYPTION.Unencrypted, label: MAIL_SMTP_ENCRYPTION.Unencrypted},
  {value: MAIL_SMTP_ENCRYPTION.SSLTLS, label: MAIL_SMTP_ENCRYPTION.SSLTLS},
  {value: MAIL_SMTP_ENCRYPTION.STARTTLS, label: MAIL_SMTP_ENCRYPTION.STARTTLS},
]
export const CONDITION_CART_TYPE = {
  cart_subtotal: 'cart_subtotal',
  cart_quantities: 'cart_quantities',
  cart_item_line: 'cart_item_line'
}

export const CONDITION_CART_TYPES = [
  {value: CONDITION_CART_TYPE.cart_subtotal, label: 'res.mgt.condition_cart_type_subtotal'},
  {value: CONDITION_CART_TYPE.cart_quantities, label: 'res.mgt.condition_cart_type_quantities'},
  {value: CONDITION_CART_TYPE.cart_item_line, label: 'res.mgt.condition_cart_type_item_line'}
]

export const CONDITION_METHOD_TYPE = {
  less_than: 'less_than',
  less_than_or_equal: 'less_than_or_equal',
  greater_than: 'greater_than',
  greater_than_or_equal: 'greater_than_or_equal',
  range: 'range',
  in_list: 'in_list',
  not_in_list: 'not_in_list'
}

export const CONDITION_CART_METHOD_TYPES = [
  {value: CONDITION_METHOD_TYPE.greater_than, label: 'res.mgt.greater_than'},
  {value: CONDITION_METHOD_TYPE.greater_than_or_equal, label: 'res.mgt.greater_than_or_equal'},
  {value: CONDITION_METHOD_TYPE.less_than, label: 'res.mgt.less_than'},
  {value: CONDITION_METHOD_TYPE.less_than_or_equal, label: 'res.mgt.less_than_or_equal'}
]

export const CONDITION_DATE_TIME_METHOD_TYPES = [
  {value: CONDITION_METHOD_TYPE.in_list, label: 'res.mgt.in_list'},
  {value: CONDITION_METHOD_TYPE.not_in_list, label: 'res.mgt.not_in_list'}
]

export const DISCOUNT_VALUE_TYPE = {
  percentage: 'percentage',
  amount: 'amount',
  price: 'price'
}

export const DISCOUNT_VALUE_TYPES = [
  {value: DISCOUNT_VALUE_TYPE.percentage, label: 'res.mgt.percentage'},
  {value: DISCOUNT_VALUE_TYPE.amount, label: 'res.mgt.amount_value'},
  {value: DISCOUNT_VALUE_TYPE.price, label: 'res.mgt.fixed_price'}
]

export const PROMOTION_RULE_TYPE = {
  cart_adjustment: 'cart_adjustment',
  product_adjustment: 'product_adjustment',
  product_dependent: 'product_dependent',
  product_quantity: 'product_quantity',
  product_bxgx: 'product_bxgx',
  product_bxgy: 'product_bxgy',
  manual_order_discount: 'manual_order_discount'
}
export const PROMOTION_RULE_TYPES = [
  {value: PROMOTION_RULE_TYPE.cart_adjustment, label: 'res.mgt.rule_type_cart_adjustment'},
  {value: PROMOTION_RULE_TYPE.product_adjustment, label: 'res.mgt.rule_type_product_adjustment'},
  // {value: PROMOTION_RULE_TYPE.product_dependent, label: 'res.mgt.rule_type_product_dependent'},
  {value: PROMOTION_RULE_TYPE.product_quantity, label: 'res.mgt.rule_type_bulk_discount'},
  {value: PROMOTION_RULE_TYPE.product_bxgx, label: 'res.mgt.rule_type_buy_x_get_x'},
  {value: PROMOTION_RULE_TYPE.product_bxgy, label: 'res.mgt.rule_type_buy_x_get_y'}
]

export const CONDITION_BUY_TYPE = {
  any: 'any',
  each: 'each',
  combine: 'combine'
}

export const CONDITION_BUY_TYPES = [
  {value: CONDITION_BUY_TYPE.any, label: 'res.mgt.condition_buy_type_any'},
  {value: CONDITION_BUY_TYPE.each, label: 'res.mgt.condition_buy_type_each'},
  {value: CONDITION_BUY_TYPE.combine, label: 'res.mgt.condition_buy_type_combine'}
]

export const CONDITION_APPLY_TO_TYPE = {
  all_product: 'all_product',
  specific_menus: 'specific_menus',
  specific_categories: 'specific_categories',
  specific_products: 'specific_products'
}

export const CONDITION_APPLY_TO_TYPES = [
  {value: CONDITION_APPLY_TO_TYPE.all_product, label: 'res.mgt.all_dishes'},
  {value: CONDITION_APPLY_TO_TYPE.specific_menus, label: 'res.mgt.specific_menus'},
  {value: CONDITION_APPLY_TO_TYPE.specific_categories, label: 'res.mgt.specific_categories'},
  {value: CONDITION_APPLY_TO_TYPE.specific_products, label: 'res.mgt.specific_dish'}
]

export const CONDITION_APPLY_FOR_TYPE = {
  same_product: 'same_product',
  cheapest_product: 'cheapest_product',
  specific_product: 'specific_product'
}

export const CONDITION_APPLY_FOR_TYPES = [
  {value: CONDITION_APPLY_FOR_TYPE.same_product, label: 'res.mgt.condition_apply_for_type_same_product'},
  // {value: CONDITION_APPLY_FOR_TYPE.cheapest_product, label: 'res.mgt.condition_apply_for_type_cheapest_product'},
  {value: CONDITION_APPLY_FOR_TYPE.specific_product, label: 'res.mgt.condition_apply_for_type_specific_product'}
]

export const VOUCHER_STATUS = {
  New: 'New',
  Used: 'Used'
}

export const PROMOTION_TYPE = {
  promotion: 'promotion',
  manual_discount: 'manual_discount'
}

export const PROMOTION_TYPES = [
  {value: PROMOTION_TYPE.promotion, label: 'res.mgt.promotion'},
  {value: PROMOTION_TYPE.manual_discount, label: 'res.mgt.manual_discount'}
]

export const RULE_FILTER_OBJECT = {
  all_product: 'all_product',
  specific_products: 'specific_products',
  specific_menus: 'specific_menus',
  specific_categories: 'specific_categories'
}

export const RULE_FILTER_OBJECTS = [
  {value: RULE_FILTER_OBJECT.all_product, label: 'res.mgt.rule_filter_obj_all_dish'},
  {value: RULE_FILTER_OBJECT.specific_products, label: 'res.mgt.rule_filter_obj_specific_dish'},
  {value: RULE_FILTER_OBJECT.specific_menus, label: 'res.mgt.rule_filter_obj_specific_menus'},
  {value: RULE_FILTER_OBJECT.specific_categories, label: 'res.mgt.rule_filter_obj_specific_categories'}
]

export const RULE_FILTER_TYPE = {
  in_list: 'in_list',
  not_in_list: 'not_in_list'
}

export const RULE_FILTER_TYPES = [
  {value: RULE_FILTER_TYPE.in_list, label: 'res.mgt.in_list'},
  {value: RULE_FILTER_TYPE.not_in_list, label: 'res.mgt.not_in_list'}
]

export const RULE_CONDITION_TYPE = {
  subtotal: "subtotal",
  item_quantity: "item_quantity",
  line_item_count: "line_item_count",
  week_days: "week_days",
  month_days: "month_days",
  times: "times"
}

export const WEEK_DAYS = [
  {value: 'monday', label: 'res.mgt.mon'},
  {value: 'tuesday', label: 'res.mgt.tue'},
  {value: 'wednesday', label: 'res.mgt.wed'},
  {value: 'thursday', label: 'res.mgt.thu'},
  {value: 'friday', label: 'res.mgt.fri'},
  {value: 'saturday', label: 'res.mgt.sat'},
  {value: 'sunday', label: 'res.mgt.sun'}
];

export const ORDER_ACTIVITY_TYPE = {
  resMgt: 'resMgt',
  cusMgt: 'cusMgt',
  orderPlaced: 'orderPlaced',
  orderConfirmed: 'orderConfirmed',
  orderCheckout: 'orderCheckout',
  orderPaymentVerified: 'orderPaymentVerified',
  orderCompleted: 'orderCompleted',
  orderDelivered: 'orderDelivered',
  orderCanceled: 'orderCanceled'
}

export const MONTH_DAYS = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
  {value: '6', label: '6'},
  {value: '7', label: '7'},
  {value: '8', label: '8'},
  {value: '9', label: '9'},
  {value: '10', label: '10'},
  {value: '11', label: '11'},
  {value: '12', label: '12'},
  {value: '13', label: '13'},
  {value: '14', label: '14'},
  {value: '15', label: '15'},
  {value: '16', label: '16'},
  {value: '17', label: '17'},
  {value: '18', label: '18'},
  {value: '19', label: '19'},
  {value: '20', label: '20'},
  {value: '21', label: '21'},
  {value: '22', label: '22'},
  {value: '23', label: '23'},
  {value: '24', label: '24'},
  {value: '25', label: '25'},
  {value: '26', label: '26'},
  {value: '27', label: '27'},
  {value: '28', label: '28'},
  {value: '29', label: '29'},
  {value: '30', label: '30'},
  {value: '31', label: '31'}
];

export const DEFAULT_RULE_FILTER = {applyObject: RULE_FILTER_OBJECT.all_product, applyType: RULE_FILTER_TYPE.in_list, applyTo: []};
export const DEFAULT_RULE_DISCOUNT = {
  loop: false,
  minQuantity: 0,
  maxQuantity: 0,
  discountValue: 0,
  discountValueType: DISCOUNT_VALUE_TYPE.percentage,
  applyQuantity: 0,
  applyForProduct: ''
};
export const DEFAULT_RULE_CONDITION = {
  conditionType: RULE_CONDITION_TYPE.subtotal,
  methodType: CONDITION_METHOD_TYPE.greater_than,
  values: new Array(1),
  sValues: []
};

export const DROPDOWN_MULTI_STYLES = {
  control: styles => ({ ...styles, 
    backgroundColor: 'white', color:'yellow', border: '1px solid #ced4da', borderRadius: '2px', padding: '0', minHeight: '34px',
    ':hover':{
      border: '1px solid #5aaf4b',
      boxShadow: '0 0 0 3px rgba(0,116,217,0.2)'
    }
  }),
  valueContainer: (style, {data}) => {return {...style, padding: '2px 5px'}},
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#5aaf4b',
      color: '#fff'
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#038459'
    },
  })
};

export const DEVICE_TYPE = {
  printer: 'printer'
}

export const PRODUCT_CLASSIFICATION = {
  simple: 'simple',
  variable: 'variable',
  configuration: 'configuration'
}

export const USE_PURPOSE = {
  dineIn: 'dineIn',
  takeAway: 'takeAway',
  dineInSplit: 'dineInSplit'
}

export const TABLE_CONFIRM_ACTION = {
  askCoupon: 'askCoupon',
  unMergeTable: 'unMergeTable'
}

export const FILTER_DATE_TYPES = {
  today: {label: 'res.mgt.today', value: 'today'},
  yesterday: {label: 'res.mgt.yesterday', value: 'yesterday'},
  thisWeek: {label: 'res.mgt.this_week', value: 'thisWeek'},
  thisMonth: {label: 'res.mgt.this_month', value: 'thisMonth'},
  lastWeek: {label: 'res.mgt.last_week', value: 'lastWeek'},
  lastMonth: {label: 'res.mgt.last_month', value: 'lastMonth'},
  last2Month: {label: 'res.mgt.last_two_months', value: 'last2Month'},
  last3Month: {label: 'res.mgt.last_three_months', value: 'last3Month'}
}

export const FORM_ACTION = {
  add: 'add',
  edit: 'edit',
  remove: 'remove'
}

export const ORDER_BATCH_TYPE = {
  pending: 'pending',
  preparing: 'preparing',
  completed: 'completed',
  canceled: 'canceled'
}