import Loadable from 'react-loadable';
import Loading from './Loading';

const lazyLoad = (loader) => {
  return Loadable({
    loader: loader,
    loading: Loading
  });
}

export default lazyLoad;
