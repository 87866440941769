import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import lazyLoad from './core/components/lazyLoad';
import { AppContext } from './core/context/AppContext';

import AuthRoute from './core/security/AuthRoute.jsx';
import { getDefaultLocale, getSeletecLocale } from './core/service/CommonService';
import { loadPublicConfiguration } from './service/ConfigurationService';

const Login = lazyLoad(() => import('./login/Login'));
const ResetPassword = lazyLoad(() => import('./reset-password/ResetPassword'));
const ResetPasswordLink = lazyLoad(() => import('./reset-password/ResetPasswordLink'));
const Restaurant = lazyLoad(() => import('./Restaurant'));

// export const Main = () => {
// 	return (
// 		<Switch>
// 				<Redirect exact from="/" to="/mgt/user/profile" />
// 				<Route path="/login" component={Login}/>
// 				<Route path="/reset-password" component={ResetPassword}/>
// 				<Route path="/confirm-change-pass" component={ResetPasswordLink}/>
// 				{/* <Route path="/recover-username" component={RecoverUsername}/> */}
				
// 				<AuthRoute path='/mgt' component={Restaurant} />
// 				{/* <AuthRoute path="/500" component={ErrorPage} /> */}
// 				{/* <AuthRoute path="/404" component={NotFound} /> */}
// 				{/* <AuthRoute path="/401" component={AccessDinene} /> */}
// 			</Switch>
// 	);
// }

class Main extends Component {
	constructor(props) {
		super(props)
		this.state = {
			appConfig: null,
			cLang: 'en-US'
		}
	}

	async componentDidMount(){
		this.loadAppConfig()
	
		const locale = getSeletecLocale()??getDefaultLocale()
		this.changeLanguage(locale);
	}
	
	loadAppConfig = async () => {
    const appConfig = await loadPublicConfiguration()
    this.setState({appConfig: appConfig})
  }

	changeLanguage = (e) => {
		this.setState({ cLang: e });
		const { i18n } = this.props;
		i18n.changeLanguage(e);
		localStorage.setItem('selectedLocale', e);
	}
	
	render() {
		const { t } = this.props
		const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" })

		return (
			<AppContext.Provider value={{
				trans: trans,
				appConfig: this.state.appConfig,
				dLang: getDefaultLocale(),
				cLang: getSeletecLocale(),
				changeLanguage: (e) => this.changeLanguage(e),
				reloadConfig: () => this.loadAppConfig()
			}}>
				<Switch>
					<Redirect exact from="/" to="/mgt/user/profile" />
					<Route path="/login" component={Login}/>
					<Route path="/reset-password" component={ResetPassword}/>
					<Route path="/confirm-change-pass" component={ResetPasswordLink}/>
					{/* <Route path="/recover-username" component={RecoverUsername}/> */}
					
					<AuthRoute path='/mgt' component={Restaurant} />
					{/* <AuthRoute path="/500" component={ErrorPage} /> */}
					{/* <AuthRoute path="/404" component={NotFound} /> */}
					{/* <AuthRoute path="/401" component={AccessDinene} /> */}
				</Switch>
			</AppContext.Provider>
		)
	}
}

export default withNamespaces('message')(Main)