import EventEmitter from 'eventemitter3';

export const messageEvent = new EventEmitter();

export function showMessage(severity, summary, detail, sticky = false) {
  messageEvent.emit('MESSAGE', {severity, summary, detail, sticky});
}

export function showInfoMessage(summary, detail, sticky = false) {
  messageEvent.emit('MESSAGE', {severity: 'info', summary, detail, sticky});
}