import React from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';

const Loading = () => {
  return ( <div style={styles.centerAlign}>
      <ProgressSpinner strokeWidth="2"/>
    </div>
  );
}

const styles = {
  centerAlign: { textAlign: "center" }
}

export default Loading;
