import React, { Component } from 'react';
import { Messages } from 'primereact/messages';
import { messageEvent } from '../service/MessageService';

export default class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillMount() {
    messageEvent.on('MESSAGE', this.showMessage)
  }

  showMessage = ({severity, summary, detail, sticky}) => { 
    this.messages.show({severity, summary, detail, sticky});
  }

  render() {
    return <Messages ref={(el) => this.messages = el} />
  }

  componentWillUnmount() {
    messageEvent.removeListener('MESSAGE', this.showMessage)
  }
}
