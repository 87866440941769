export const isAuthenticated = () => localStorage.getItem('access_token') !== null;
// export const isAuthenticated = () => true;

// const currentUserRoles = JSON.parse(localStorage.getItem('roles'));
export const isSuperAdmin = () => {
  return isAuthenticated() && localStorage.getItem('isSuperAdmin') === 'YES';
}

export const isResAdmin = () => {
  return isAuthenticated() && (localStorage.getItem('isSuperAdmin') === 'YES' || localStorage.getItem('isResAdmin') === 'YES');
}

export const hasRole = (roles) => {
  if(localStorage.getItem('isSuperAdmin') === 'YES' || localStorage.getItem('isResAdmin') === 'YES') return isAuthenticated();
  return isAuthenticated() && roles.some(role => localStorage.getItem('roles').includes(role));
}

const b64DecodeUnicode = str =>
  decodeURIComponent(
    Array.prototype.map.call(atob(str), c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))

const parseJwt = token =>
  JSON.parse(
    b64DecodeUnicode(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    )
  )

export const saveLoginInfo = ({ access_token, refresh_token }, { authorities }) => {
  if(access_token && refresh_token) {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    const userDetails = parseJwt(access_token);
    const refreshToken = parseJwt(refresh_token);

    localStorage.setItem('tokenExp', userDetails.exp);
    localStorage.setItem('refreshExp', refreshToken.exp);

    localStorage.setItem('roles', authorities.join());
    if(authorities.includes('SUPER_ADMIN')){
      localStorage.setItem('isSuperAdmin', 'YES');
    } else {
      localStorage.setItem('isSuperAdmin', 'NO');
    }
    if(authorities.includes('RES_ADMIN')){
      localStorage.setItem('isResAdmin', 'YES');
    } else {
      localStorage.setItem('isResAdmin', 'NO');
    }
    localStorage.setItem('username', userDetails.user_name);
    localStorage.setItem('fullname', userDetails.name);
    localStorage.setItem('user_id', userDetails.id);
    localStorage.setItem('client_id', userDetails.tenantId);
    localStorage.setItem('depts', JSON.stringify(userDetails.depts));
    localStorage.setItem('personalData', {
      'notifications': []
    });
  }
}

export const logOut = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('tokenExp');
  localStorage.removeItem('refreshExp');

  localStorage.removeItem('roles');
  localStorage.removeItem('isSuperAdmin');
  localStorage.removeItem('isResAdmin');
  localStorage.removeItem('username');
  localStorage.removeItem('fullname');
  localStorage.removeItem('user_id');
  
  localStorage.removeItem('client_id');
  localStorage.removeItem('tenant_country_code');
  localStorage.removeItem('tenant_state_code');
  localStorage.removeItem('depts');
  localStorage.removeItem('personalData');

  localStorage.removeItem('selectedLocale');
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
}

export const loggedIn = () => {
  return localStorage.auth;
}
