export const whitelist = [
  'login',
  'session',
  'api/orders/customer', 
  'api/rating/customer', 
  'api/orders/take-away/customer', 
  'configs/public/restaurant', 
  'menu/api/menu', 
  'api/payment/active', 
  'api/invoice', 
  'api/voucher/customer', 
  'api/order-notification', 
  'api/grab/customer', 
  '/api/delivery-orders'
]
