import axios from 'axios';
import { CONFIGURATION_BASE_URL } from '../constants';
import queryString from 'querystring';

export const loadPublicConfiguration = async () => {
    const cacheKey = `configKey`;
    const storedData = JSON.parse(localStorage.getItem(cacheKey));
    let headers = {};
    let loadFromCache = false

    if (storedData) {
        headers['If-None-Match'] = storedData.etag;
    }

    let configs = await axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/configs/public/restaurant`, {
        headers: headers
    }).catch(error => {
        if (error.response.status === 304) {
            loadFromCache = true
        }
    });

    if(!loadFromCache) {
        const etag = configs.headers.etag;
        if (etag) {
            localStorage.setItem(cacheKey, JSON.stringify({ etag, data: configs.data }));
        }
        localStorage.setItem('resConfig', JSON.stringify(configs.data));
    } else {
        configs = storedData;
    }

    const appConfig = configs.data
    
    appConfig.defaultLanguage && localStorage.setItem('defaultLocale', appConfig.defaultLanguage);
    appConfig.enabledLanguages && localStorage.setItem('enabledLocales', appConfig.enabledLanguages);
    appConfig.resApplicationUrl && localStorage.setItem('resApplicationUrl', appConfig.resApplicationUrl);

    localStorage.setItem('useDineInPinCode', appConfig.useDineInPinCode);
    localStorage.setItem('useTakeAwayPinCode', appConfig.useTakeAwayPinCode);

    localStorage.setItem('timezone', appConfig.timezone);
    localStorage.setItem('currency', appConfig.currency);

    localStorage.setItem('defaultTax', appConfig.defaultTax);
    localStorage.setItem('pricingMode', appConfig.pricingMode);
    localStorage.setItem('takeAwayTableCode', appConfig.takeAwayTable);
    localStorage.setItem('timeOrderTableCode', appConfig.timeOrderTable);
    localStorage.setItem('deliveryNowTableCode', appConfig.deliveryNowTable);
    localStorage.setItem('showStoreProducts', appConfig.showStoreProducts);
    localStorage.setItem('countryCode', appConfig.countryCode);
    localStorage.setItem('dineInPaymentMode', appConfig.dineInPaymentMode);

    localStorage.setItem('openHourStep',  appConfig.openHourStep)

    return appConfig;
}

export function getConfig(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/restaurant`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getNotifications(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/notifications`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveRestaurantConfig(data, type){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/restaurant/${type}`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveRestaurantConfigQuickbooks(data, type, dineInCustomer, takeAwayCustomer, cashAccount, voucherAccount, otherAccount, visaAccount, mastercardAccount, americanExpressAccount){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/restaurant/${type}`, data, {params: {dineInCustomer: dineInCustomer, takeAwayCustomer: takeAwayCustomer, cashAccount: cashAccount, voucherAccount: voucherAccount, otherAccount: otherAccount, visaAccount: visaAccount, mastercardAccount: mastercardAccount, americanExpressAccount: americanExpressAccount}})
    .then(res => res.data).catch(error => console.log(error));
}

export function getTimezones(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/timezone/list`)
    .then(res => res.data).catch(error => console.log(error));
}

export function updateDineInOrderPaymentMode(mode){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/restaurant/dine-in-order-mode`, queryString.stringify({ mode }))
    .then(res => res.data).catch(error => console.log(error));
}
